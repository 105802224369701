import React, { useState, useEffect } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import MenuIcon from '../../svg/MenuIcon';
import Button from '../Button';
import Headroom from 'react-headroom';

// export function scrollPos() {
//   console.log(scrollPosition);
//   const [scrollPosition, setScrollPosition] = useState(0);
//   const handleScroll = () => {
//     const position = window.pageYOffset;
//     setScrollPosition(position);
//   };
//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll, { passive: true });

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   if (scrollPosition > 0) {
//     // document.getElementById('head').style.boxShadow = '10px 10px 10px 10px';
//   }
// }

const Navbar = () => (
  <Headroom>
    <header
      id="head"
      className=" lg:px-10 px-6 bg-white flex flex-wrap items-center mx-auto lg:py-4 py-6 sticky top-0 bg-white shadow-md"
    >
      <div className="container flex-1 flex justify-between items-center mx-auto px-6">
        {/*put in own flex container to fix margin*/}
        {/* needs resizing */}
        <a href="#" className="w-1/2">
          <LogoIcon />
        </a>
        <label htmlFor="menu-toggle" className="cursor-pointer lg:hidden block">
          <MenuIcon />
        </label>

        <input type="checkbox" className="hidden" />

        <div className="hidden space-x-4 lg:flex lg:items-center lg:w-auto w-full">
          <nav>
            <AnchorLink className="navLink" href="#services">
              Services
            </AnchorLink>

            <AnchorLink className="navLink" href="#about">
              About Me
            </AnchorLink>

            <AnchorLink className="navLink" href="#testimonials">
              Testimonials
            </AnchorLink>

            <div className="hidden md:inline pl-4">
              <a href="#contact">
                <Button className="text-sm">Contact Me</Button>
              </a>
            </div>
          </nav>
        </div>
      </div>
    </header>
  </Headroom>
);

export default Navbar;
