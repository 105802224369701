export default [
  {
    title: 'Business operations and technology consultancy',
    content:
      'Risus taciti metus pharetra condimentum litora torquent elementum porttitor tempus, nonummy. Vel. Gravida torquent nulla imperdiet elit. Magna. Platea vestibulum at adipiscing. Laoreet venenatis consequat ornare sociis per mus nullam.',
    customerName: 'Nigel Aucott',
    customerTitle: 'Finance & Operations',
    customerImage: require('../svg/nigel.jpg')
  },
  {
    title: 'Intervention and dyslexia, tutoring and assessment',
    content:
      'With 7-years of experience working in the Special Educational Needs sector, Tammy is a well-suited tutor. Tammy is also an Associate Member of the British Dyslexia Association (AMBDA) and holds the SpLD Assessment Certificate (APC). ',
    customerName: 'Tammy Aucott',
    customerTitle: 'Dyslexia Specialist',
    customerImage: require('../svg/assessment.jpg')
  },
  {
    title: 'Website and online technology development',
    content:
      'Risus taciti metus pharetra condimentum litora torquent elementum porttitor tempus, nonummy. Vel. Gravida torquent nulla imperdiet elit. Magna. Platea vestibulum at adipiscing. Laoreet venenatis consequat ornare sociis per mus nullam.',
    customerName: 'Thomas Aucott',
    customerTitle: 'Developer & Designer',
    customerImage: require('../svg/tom.png')
  },
  {
    title: 'Intervention and dyslexia, tutoring and assessment',
    content: `I have 7 years of experience teaching SEN students in schools, and providing tuition to all ages.
       I'm well versed in a variety of methods and tools in order to provide specialist intervention for dyslexia.
       This includes the ability to provide online tutoring via Zoom, as well as 1-to-1 support. Further to this I
       provide a friendly dyslexia assessment service. Primarily based in Newcastle-Upon-Tyne I am also a committe member
       of Hilltop Specialist Arts College and fully DBS checked member of the British Dyslexia Association (BDA).
       If you'd like to contact me, please fill in the contact form at the bottom of this page or email me at tammy@flowingmind.co.uk`,
    customerName: 'Tammy Aucott',
    customerTitle: 'Dyslexia Specialist',
    customerImage: require('../svg/assessment.jpg')
  }
];
