import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import headerImg from '../svg/person-writing-on-notebook-4144923.jpg';
import SvgCharts from '../svg/SvgCharts';
import OnlineTutor from '../svg/OnlineTutor';
import Assessment from '../svg/Assessment';
import Intervention from '../svg/Intervention';
import Form from '../components/Form';
import { Helmet } from 'react-helmet';

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Dyslexia assessments and tuition provided online and off by Tammy Aucott, a BDA associate and SEN specialist"
      />
      <meta
        name="keywords"
        content="dyslexia, dyslexia assessment , dyslexia tutoring, dyslexia tuition, BDA tutor, online dyslexia, online dyslexia tuition, dyslexia tutor"
      />
      <title>Dyslexia Tuition Online | BDA Member</title>
      <link rel="canonical" href="https://flowingmind.co.uk" />
    </Helmet>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-6 lg:flex ">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Dyslexia Intervention
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            Decoding dylexia and SEN via proven techniques and intervention. We offer services to
            promote growth in all academic areas.
          </p>
          <p className="mt-8 md:mt-12">
            <Button size="lg">More Information</Button>
          </p>
          {/* <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p> */}
        </div>
        <div className="lg:w-1/2 lg:py-0 py-8">
          <HeroImage />
        </div>
      </div>
    </section>

    <section id="services" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">My services</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8 flex flex-col items-center">
              <OnlineTutor />
              <p className="font-semibold text-xl">Online Tuition</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8 flex flex-col items-center">
              <Assessment />
              <p className="font-semibold text-xl">Assessments</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8  flex flex-col items-center">
              <Intervention />
              <p className="font-semibold text-xl">Intervention</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>

    <section id="about" className="">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">About Me</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.slice(3, 4).map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />{' '}
            </div>
          ))}
        </div>
      </div>
    </section>

    <section className="py-20 lg:py-40">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">A family team</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.slice(0, 3).map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>

    {/* <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Market Analysis</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio velna
            vitae auctor integer.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">An enim nullam tempor</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio velna
            vitae auctor integer.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">An enim nullam tempor</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio velna
            vitae auctor integer.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    /> */}

    {/* <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">An enim nullam tempor</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section> */}

    <section
      id="contact"
      className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center"
    >
      <h3 className="text-5xl font-semibold">Contact Me</h3>
      <p className="mt-8 text-xl font-light">
        Fill in the form with your details and I'll get back to you as soon as I can.
        <br />
        Rather email me? tammy@flowingmind.co.uk
      </p>
      <div className="flex justify-center p-8">
        <Form />
      </div>

      {/* <p className="mt-8">
        <Button size="xl">Get Started Now</Button>
      </p> */}
    </section>
  </Layout>
);
