import React from 'react';

const HeroImage = () => (
  <svg
    id="a02f2390-8fa7-48a9-96f1-2ec5f63ecbc1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1113.58 784.41"
  >
    <title>teacher</title>
    <path
      d="M1162.81,718.44A188.54,188.54,0,0,1,1146.4,750a135.82,135.82,0,0,1-9.94,13.67.7.7,0,0,1-.11.12H25l.15-.12c1-.78,6.87-5.53,16-13.67,6.55-5.83,14.77-13.41,24-22.53,75.49-74.25,220.5-250,99.73-411-112.27-149.67,8.61-176.83,88.35-180a227.81,227.81,0,0,1,89.86,14.69c36.84,14,104.17,35.46,191.89,45.3,2.88.32,5.78.64,8.72.93a840.12,840.12,0,0,0,139.78,2.54h0c1.37-.08,2.75-.18,4.14-.28a830.62,830.62,0,0,0,136.72-21.15c2.91-.71,5.78-1.38,8.64-2.05,149.63-34.62,287.57,83.37,253.08,216.71-12.75,49.32-9.51,101.89,33,144.42C1188.66,607,1180.23,675.37,1162.81,718.44Z"
      transform="translate(-25 -136.26)"
      fill="#4199e1"
      opacity="0.1"
    />
    <path
      d="M1162.81,718.44A188.54,188.54,0,0,1,1146.4,750a135.82,135.82,0,0,1-9.94,13.67H25.15c1-.78,6.87-5.53,16-13.67,6.55-5.83,14.77-13.41,24-22.53l117.13-24.2a22.79,22.79,0,0,1,4.6-.47l124.87-.18h.13l61.85-.08h.14l52.2-.06,34.17,0h0l35,0,143.42-.2h.23l11.11,0,19.52,0,24.37,0h6.48L725,702h8.14l71.64-.1,22.88,0h0l11,0h0l82.75-.11,24.64,0,78.38-.11,66.16-.08a23.45,23.45,0,0,1,5.47.65Z"
      transform="translate(-25 -136.26)"
      fill="#454b69"
    />
    <path
      d="M41.15,750H1146.4a135.82,135.82,0,0,1-9.94,13.67H25.15C26.13,762.84,32,758.09,41.15,750Z"
      transform="translate(-25 -136.26)"
      fill="none"
    />
    <path
      d="M41.15,750H1146.4a135.82,135.82,0,0,1-9.94,13.67H25.15C26.13,762.84,32,758.09,41.15,750Z"
      transform="translate(-25 -136.26)"
      opacity="0.4"
    />
    <path
      d="M758.22,439l-2.22.43L655.64,458.71c-29.15,5.6-58.72,11.23-88.33,9.08-21.45-1.56-41.49-7.92-62.1-13.5-11.65-3.16-24-4.42-27.95-17.46-5.45-18,3.42-44.65,5.51-62.89,2-17.9,3.18-36.25-.38-53.94a95.9,95.9,0,0,0-3.12-11.74c-3.57-10.59-9.18-21.6-6.15-32.35,2.39-8.53,9.68-14.62,16.5-20.26,13.36-11,27-23.33,32.3-39.82,2.6-8,3.09-16.61,5.89-24.55,5.31-15.1,18.65-26.39,33.49-32.37s31.16-7.25,47.17-7.25a319.6,319.6,0,0,1,42.27,2.81c15.28,2,30.7,5.29,44.13,12.87s24.75,20,27.88,35.14c4.43,21.41-7.87,44.21-1.27,65,3.32,10.51,11,19,16.33,28.62a68.17,68.17,0,0,1,5,53c-6.38,19.77-22,39.77-14,58.94,5.09,12.19,18.09,18.7,27.15,28.36Q757.17,437.7,758.22,439Z"
      transform="translate(-25 -136.26)"
      fill="#454b69"
    />
    <path
      d="M756,436.48v2.95L655.64,458.71c-29.15,5.6-58.72,11.23-88.33,9.08-21.45-1.56-41.49-7.92-62.1-13.5-11.65-3.16-24-4.42-27.95-17.46-5.45-18,3.42-44.65,5.51-62.89,2-17.9,32.79-83.25,29.23-100.94l184,8c1.24,9.77,49.87,58.81,46.84,68.18-6.38,19.77-22,39.77-14,58.94C733.94,420.31,746.94,426.82,756,436.48Z"
      transform="translate(-25 -136.26)"
      opacity="0.1"
    />
    <path
      d="M568,387c1.09,6.19,1.67,13-1.49,18.4-4.21,7.24-13.39,9.55-21.6,11.19l-28.5,5.7c-1.94.39-4,.83-5.45,2.16-1.71,1.53-2.29,3.95-2.54,6.23-1.15,10.32,2.5,20.49,5.83,30.32,6.73,19.91,12.38,40.24,20.89,59.45S555.33,558,572,570.84s38.73,19.68,59.14,14.66c17.78-4.37,32.36-16.92,44.93-30.23,10.37-11,20.07-23,25.1-37.27,3.47-9.8,4.6-20.24,5.69-30.58q2.53-23.78,5.06-47.56c.79-7.44,1.31-15.81-3.52-21.52-2.12-2.51-5-4.18-7.94-5.73C689.52,406.8,678,402,667.82,395s-19.21-16.64-22.3-28.6c-1.85-7.18-1.52-15-4.67-21.69-3.52-7.5-11-12.58-19-14.81s-16.44-1.93-24.68-1-40,3.31-43.41,12.6c-1.83,5,5.76,15.73,7.56,20.65A144.17,144.17,0,0,1,568,387Z"
      transform="translate(-25 -136.26)"
      fill="#efb7b9"
    />
    <path
      d="M568,387c1.09,6.19,1.67,13-1.49,18.4-4.21,7.24-13.39,9.55-21.6,11.19l-28.5,5.7c-1.94.39-4,.83-5.45,2.16-1.71,1.53-2.29,3.95-2.54,6.23-1.15,10.32,2.5,20.49,5.83,30.32,6.73,19.91,12.38,40.24,20.89,59.45S555.33,558,572,570.84s38.73,19.68,59.14,14.66c17.78-4.37,32.36-16.92,44.93-30.23,10.37-11,20.07-23,25.1-37.27,3.47-9.8,4.6-20.24,5.69-30.58q2.53-23.78,5.06-47.56c.79-7.44,1.31-15.81-3.52-21.52-2.12-2.51-5-4.18-7.94-5.73C689.52,406.8,678,402,667.82,395s-19.21-16.64-22.3-28.6c-1.85-7.18-1.52-15-4.67-21.69-3.52-7.5-11-12.58-19-14.81s-16.44-1.93-24.68-1-40,3.31-43.41,12.6c-1.83,5,5.76,15.73,7.56,20.65A144.17,144.17,0,0,1,568,387Z"
      transform="translate(-25 -136.26)"
      opacity="0.1"
    />
    <path
      d="M636.59,495.74l16.71-35.38c7.83-16.58,15.83-34,15-52.31-.16-3.64-.2-8.19,3-9.84a8.58,8.58,0,0,1,4.39-.56,177.59,177.59,0,0,1,51.51,10.42c3.21,1.16,6.5,2.5,8.75,5.06s3.16,6,3.93,9.3a271.54,271.54,0,0,1,5.73,33.87c3,28.22,1.51,56.69,0,85l-4.44,86.43a8.26,8.26,0,0,1-.73,3.56c-1.1,2-3.58,2.67-5.81,3.05-8.59,1.47-17.37,1.17-26.07.84-61.2-2.32-122.72-5.88-183.52,1.54q.41-13.49-.43-27c-2.41-38.89-12.49-76.83-22.39-114.51-5.77-22-11.36-46-2.07-66.73a28.59,28.59,0,0,1,5.79-8.82c3.95-3.87,9.34-5.91,14.68-7.37a93.43,93.43,0,0,1,28.29-3.2l63.05,137.27c2.88,6.28,5.25,12.89,8.12,19.09,2.58-10.71-.52-23.26,2.58-34.87C626,518.53,631.26,507,636.59,495.74Z"
      transform="translate(-25 -136.26)"
      fill="#fff"
    />
    <path
      d="M630,590.19c11.28.71,22.24-5.07,30-13.24s12.88-18.53,17.21-29A286,286,0,0,0,692,501.84c3-13.4,5.13-27.27,3.06-40.85-2.86-18.75-13.47-35.79-15.28-54.67-.31-3.3-.11-7.16,2.54-9.15,1.64-1.23,3.8-1.4,5.84-1.52,18.86-1.12,39.58-.91,54.19,11.06,4.45,3.64,8.06,8.19,12.41,11.94,10.36,9,25,13.58,31.65,25.53,2.32,4.16,3.43,8.87,5.05,13.35,3.82,10.6,10.43,19.92,17,29.09-6.37,26.88-13.69,53.78-20.06,80.66-3.73,15.77-7.49,31.6-13.35,46.7-3.84,9.9-8.67,20-7.49,30.6.47,4.17,1.86,8.16,3.07,12.18a140,140,0,0,1,5.89,43.53l-285.34.7c13.67-19.6,17.68-43.64,16.29-67.49-.67-11.43-3.25-22.93-9-32.82-9.06-15.5-25-25.6-40.71-34.25-11-6-22.81,27.76-34.7,23.89,1.92-13.58,2.8-66,2-79.67-.26-4.48-.66-9.08.72-13.36.94-2.93,2.67-5.55,3.84-8.4,4.69-11.41.22-26,7.8-35.71,5.24-6.75,14.43-8.67,21.9-12.83,7.67-4.28,13.6-11,20.28-16.74A90.19,90.19,0,0,1,525.76,403a58.14,58.14,0,0,1,8.81,26c.51,5.81.14,11.7.95,17.48,1.11,7.83,4.35,15.18,7.56,22.42,13.37,30.19,26.79,60.47,43.73,88.82,4.79,8,10,16,16.94,22.23S620.68,590.63,630,590.19Z"
      transform="translate(-25 -136.26)"
      fill="#4199e1"
    />
    <path
      d="M630,590.19c11.28.71,22.24-5.07,30-13.24s12.88-18.53,17.21-29A286,286,0,0,0,692,501.84c3-13.4,5.13-27.27,3.06-40.85-2.86-18.75-13.47-35.79-15.28-54.67-.31-3.3-.11-7.16,2.54-9.15,1.64-1.23,3.8-1.4,5.84-1.52,18.86-1.12,39.58-.91,54.19,11.06,4.45,3.64,8.06,8.19,12.41,11.94,10.36,9,25,13.58,31.65,25.53,2.32,4.16,3.43,8.87,5.05,13.35,3.82,10.6,10.43,19.92,17,29.09-6.37,26.88-13.69,53.78-20.06,80.66-3.73,15.77-7.49,31.6-13.35,46.7-3.84,9.9-8.67,20-7.49,30.6.47,4.17,1.86,8.16,3.07,12.18a140,140,0,0,1,5.89,43.53l-285.34.7c13.67-19.6,17.68-43.64,16.29-67.49-.67-11.43-3.25-22.93-9-32.82-9.06-15.5-25-25.6-40.71-34.25-11-6-22.81,27.76-34.7,23.89,1.92-13.58,2.8-66,2-79.67-.26-4.48-.66-9.08.72-13.36.94-2.93,2.67-5.55,3.84-8.4,4.69-11.41.22-26,7.8-35.71,5.24-6.75,14.43-8.67,21.9-12.83,7.67-4.28,13.6-11,20.28-16.74A90.19,90.19,0,0,1,525.76,403a58.14,58.14,0,0,1,8.81,26c.51,5.81.14,11.7.95,17.48,1.11,7.83,4.35,15.18,7.56,22.42,13.37,30.19,26.79,60.47,43.73,88.82,4.79,8,10,16,16.94,22.23S620.68,590.63,630,590.19Z"
      transform="translate(-25 -136.26)"
      opacity="0.1"
    />
    <circle cx="589.91" cy="162.75" r="85.72" fill="#efb7b9" />
    <path
      d="M569.93,413.24c2.35-22.18-6.87-43.88-17.31-63.59-5-9.42-10.36-18.81-12.87-29.16s-1.79-22.07,4.63-30.58c5.46-7.24,14.12-11.15,22.13-15.4,22.61-12,43.28-29.64,53.22-53.23-5.62,3.06-4.16,12.19.8,16.22s11.72,4.81,17.82,6.73c22.24,7,35.58,29.18,46.28,49.9,1.75,3.38,3.53,6.95,3.51,10.76s-1.76,7.19-3.58,10.43c-5.28,9.4-11.74,18.43-14.14,28.94-2.75,12,.15,24.62,3.92,36.39,4,12.52,9.31,25.14,18.91,34.12s24.49,13.44,36.3,7.64c-11.79-20.27-24-42.92-19.4-65.9,2.22-11,8.19-21,10.73-31.94,4.84-20.87-3.22-42.34-11.13-62.26l-23.64-59.45c-3.61-9.09-7.72-18.79-16-23.94-5.6-3.47-12.38-4.37-18.94-5-21.12-1.88-43-1.26-62.66,6.6-35.82,14.31-58.28,49.59-76.1,83.81-5.34,10.25-10.61,21.14-10.39,32.7.12,6.34,1.89,12.51,3.33,18.69,5.86,25.16,6.18,51.23,6.48,77.06.23,19.82-.8,38.5-9.47,56.55,11.88,2.36,29.11,8.73,40.87,4.07C558.56,447.34,568,428.67,569.93,413.24Z"
      transform="translate(-25 -136.26)"
      fill="#454b69"
    />
    <g opacity="0.1">
      <path
        d="M511.78,392.36q.06-4.23,0-8.54c-.3-25.83-.62-51.9-6.48-77.06a130.05,130.05,0,0,1-3.06-14.88,38.89,38.89,0,0,0-.27,5.19c.12,6.34,1.89,12.51,3.33,18.69C511.13,340.77,511.49,366.68,511.78,392.36Z"
        transform="translate(-25 -136.26)"
      />
      <path
        d="M720.88,324.57a60.51,60.51,0,0,0,1.3-17.82,55.54,55.54,0,0,1-1.3,8.82c-2.54,11-8.51,20.91-10.73,31.94a53,53,0,0,0-.8,14.78,52.45,52.45,0,0,1,.8-5.78C712.37,345.48,718.34,335.53,720.88,324.57Z"
        transform="translate(-25 -136.26)"
      />
      <path
        d="M687.36,300.3a14.41,14.41,0,0,0,.78-4.41c0-3.81-1.76-7.38-3.51-10.76-10.7-20.72-24-42.88-46.28-49.9-6.1-1.92-12.85-2.7-17.82-6.73a12.68,12.68,0,0,1-4.38-8.8c-10.84,19.9-29.46,35.12-49.64,45.81-8,4.25-16.67,8.16-22.13,15.4-5.13,6.8-6.62,15.65-5.73,24.21a30.61,30.61,0,0,1,5.73-15.21c5.46-7.24,14.12-11.15,22.13-15.4,20.18-10.69,38.8-25.91,49.64-45.81a12.68,12.68,0,0,0,4.38,8.8c5,4,11.72,4.81,17.82,6.73,22.24,7,35.58,29.18,46.28,49.9A39.4,39.4,0,0,1,687.36,300.3Z"
        transform="translate(-25 -136.26)"
      />
      <path
        d="M693.25,405.77c-9.6-9-14.89-21.6-18.91-34.12a98.77,98.77,0,0,1-4.8-21.71c-.93,10.27,1.62,20.78,4.8,30.71,4,12.52,9.31,25.14,18.91,34.12s24.49,13.44,36.3,7.64c-1.42-2.43-2.83-4.9-4.23-7.39C714.35,418.11,701.75,413.71,693.25,405.77Z"
        transform="translate(-25 -136.26)"
      />
      <path
        d="M569.93,404.24c-1.93,15.43-11.37,34.1-26.74,40.2-10.67,4.23-25.86-.63-37.45-3.32a86.63,86.63,0,0,1-3.42,8.25c11.88,2.36,29.11,8.73,40.87,4.07,15.37-6.1,24.81-24.77,26.74-40.2a69.48,69.48,0,0,0,.22-11.62C570.09,402.49,570,403.37,569.93,404.24Z"
        transform="translate(-25 -136.26)"
      />
    </g>
    <path
      d="M691.1,652.18c-15.45-2.45-31.13-7.18-46.45-4-8.88,1.84-17,6.26-24.38,11.6,4.06,3.25,8.8,6.15,11.38,10.67a42,42,0,0,1,3.58,9.87c1.62,6,3.25,12.12,3.5,18.36s-1,12.74-4.72,17.77c19.48,1.92,38.36-2.88,57.36-7.57a15.71,15.71,0,0,0,6.07-2.45c3-2.36,3.88-6.56,3.9-10.39,0-6.84-1.92-13.51-3.87-20.07C695.15,668.1,693.43,660,691.1,652.18Z"
      transform="translate(-25 -136.26)"
      fill="#fff"
    />
    <path
      d="M795.42,483.51c7.67,1,13.62,7.19,18,13.55,12.29,17.7,17.59,39.16,23.49,59.89q11,38.67,25.87,76.12a114.56,114.56,0,0,0,7.76,16.7c2.47,4.13,5.47,8.1,6.56,12.79,2.64,11.34-6.71,22-16.46,28.32A65.14,65.14,0,0,1,843.79,699c-5.82,1.74-11.89,2.4-17.92,3.06l-55.51,6c-25.55,2.78-51.33,5.56-76.89,2.88-1.61-5.6,1.55-9.56,1.32-15.39-.16-4.17-2-8.05-3.64-11.89a76,76,0,0,1-5.93-21.51c-.29-2.73-.39-5.53-1.43-8.07s-3.27-4.82-6-5c15-1.49,26.39-4.32,41.39-5.82,5.57-.56,11.36-1.19,16.11-4.15,5.89-3.67,9.19-10.29,11.81-16.71,6.49-15.9,10.67-33.42,7.26-50.26-2.8-13.81-10.61-26.67-10.57-40.76,0-8.39,2.86-16.52,6.24-24.2,3.58-8.12,8.25-16.42,16.11-20.54S786.55,482.31,795.42,483.51Z"
      transform="translate(-25 -136.26)"
      fill="#4199e1"
    />
    <path
      d="M795.42,483.51c7.67,1,13.62,7.19,18,13.55,12.29,17.7,17.59,39.16,23.49,59.89q11,38.67,25.87,76.12a114.56,114.56,0,0,0,7.76,16.7c2.47,4.13,5.47,8.1,6.56,12.79,2.64,11.34-6.71,22-16.46,28.32A65.14,65.14,0,0,1,843.79,699c-5.82,1.74-11.89,2.4-17.92,3.06l-55.51,6c-25.55,2.78-51.33,5.56-76.89,2.88-1.61-5.6,1.55-9.56,1.32-15.39-.16-4.17-2-8.05-3.64-11.89a76,76,0,0,1-5.93-21.51c-.29-2.73-.39-5.53-1.43-8.07s-3.27-4.82-6-5c15-1.49,26.39-4.32,41.39-5.82,5.57-.56,11.36-1.19,16.11-4.15,5.89-3.67,9.19-10.29,11.81-16.71,6.49-15.9,10.67-33.42,7.26-50.26-2.8-13.81-10.61-26.67-10.57-40.76,0-8.39,2.86-16.52,6.24-24.2,3.58-8.12,8.25-16.42,16.11-20.54S786.55,482.31,795.42,483.51Z"
      transform="translate(-25 -136.26)"
      opacity="0.08"
    />
    <path
      d="M447.81,470.52c3.63-8.62,7.32-17.52,7.42-26.87.11-10.11-4-19.71-7.14-29.31a169.49,169.49,0,0,1-7.71-36.19,5,5,0,0,1,.5-3.47c.83-1.17,2.43-1.41,3.84-1.72,6.92-1.58,12.15-7.17,16.47-12.8A140.34,140.34,0,0,0,478.05,332c3.31-7.37,7.79-16.36,15.87-16.43a458.36,458.36,0,0,1-12.08,45.25c8.45-2.16,16.48-5.3,24.93-7.46a14.93,14.93,0,0,1,5.12-.68c5.06.53,8.16,7.38,5.22,11.53,4.13,4.84,11,6.14,16.4,9.46s9.28,11.54,4.48,15.71c-.94.81-2.13,1.38-2.81,2.42-2.32,3.58,3.36,7.69,2.89,11.93-.38,3.47-4.83,5.09-8.2,4.17s-6-3.44-9-5.29-6.92-3-9.83-1.08c-.78,9.41-1.62,19-5.69,27.57-1.49,3.13-3.4,6.06-4.6,9.31a45.88,45.88,0,0,0-2.12,10.93L495,482.53C477.68,486,462.15,480.81,447.81,470.52Z"
      transform="translate(-25 -136.26)"
      fill="#efb7b9"
    />
    <path
      d="M443.58,500.27l-3.19-21.59a35.1,35.1,0,0,1-.51-5.32c0-5,2.2-9.77,4.33-14.32,17.65,8.24,36.73,11.17,56.2,11.18,2.57,0,5.5-.24,7.1-2.25,3.31,3.81,3.41,9.94,2,14.78s-4.12,9.2-5.92,13.92c-.91,2.39-1.65,5-3.39,6.84-2.7,2.91-7.08,3.41-11.06,3.39a70.71,70.71,0,0,1-12.48-1.19C466,503.73,454.24,498.36,443.58,500.27Z"
      transform="translate(-25 -136.26)"
      fill="#fff"
    />
    <path
      d="M509.41,499.39q-20.55-2.34-40.83-6.6c-6.63-1.39-13.32-2.94-20.08-2.67-4.4.18-9.13,1.06-13-1.12,3.77,12.83,4,26.35-.51,38.94-2.67,7.5-6.74,14.49-8.7,22.21-4.08,16.15,1.52,33.76-3.58,49.62a12.56,12.56,0,0,1-3.08,5.52c-1.56,1.43-3.69,2.17-5.13,3.72-2.12,2.28-2.25,5.71-2.26,8.82l-.09,27.55c-.06,17.54.17,36.14,9.43,51,10.56,17,32.79,25.81,52.14,20.64a38.76,38.76,0,0,0,14.16-6.93c6.72-5.34,11-13.06,15.19-20.58,5-9,10-18.2,11.61-28.33,1.14-7.17.5-14.49-.32-21.7-4.52-40-14.33-79.66-12.22-119.81A36.31,36.31,0,0,1,509.41,499.39Z"
      transform="translate(-25 -136.26)"
      fill="#4199e1"
    />
    <path
      d="M566.67,665.39c-6.95.4-13.95.8-20.87,0-5-.6-9.88-1.83-14.84-2.67A95.11,95.11,0,0,0,454.37,684c-3.84,3.28-7.72,8.31-5.6,12.9,2.28,4.94,9.25,4.8,14.62,3.88a44,44,0,0,0-15,11.73c-1.5,1.83-2.92,4-2.63,6.37.61,4.95,7.33,5.85,12.3,5.42-1.62,1.74-.53,4.82,1.53,6s4.62.94,6.95.51a51.52,51.52,0,0,0,14.76-5.11c-.94,2.45.69,5.32,3,6.54s5.12,1.13,7.71.71c12.22-2,22.42-10.5,34.34-13.84,19.06-5.33,39,3.17,58.81,3.77,6.2.19,12.56-.44,18.21-3,6.93-3.15,12.52-9.06,19.84-11.16,8.13-2.32,17.46.35,24.9-3.66,5.41-2.91.53-25.34-.85-29.66-2.53-7.9-5.07-13.14-13.5-13.94C612.43,659.37,588.16,664.15,566.67,665.39Z"
      transform="translate(-25 -136.26)"
      fill="#efb7b9"
    />
    <path
      d="M619.88,659.27s32.29,27.33,13.66,57.15l36-12.43V677.9c0-1.24-13.67-19.87-13.67-19.87l-16.15-2.49Z"
      transform="translate(-25 -136.26)"
      fill="#fff"
    />
    <path
      d="M256.79,521.32h12.11a25,25,0,0,1,25,25v37.11a0,0,0,0,1,0,0H231.79a0,0,0,0,1,0,0V546.32A25,25,0,0,1,256.79,521.32Z"
      fill="#3f3d56"
    />
    <path
      d="M256.79,521.32h12.11a25,25,0,0,1,25,25v37.11a0,0,0,0,1,0,0H231.79a0,0,0,0,1,0,0V546.32A25,25,0,0,1,256.79,521.32Z"
      opacity="0.1"
    />
    <circle cx="260.36" cy="530.01" r="4.97" fill="#3f3d56" />
    <circle cx="260.36" cy="523.8" r="4.97" fill="#3f3d56" />
    <circle cx="260.36" cy="517.59" r="4.97" fill="#3f3d56" />
    <circle cx="260.36" cy="511.38" r="4.97" fill="#3f3d56" />
    <circle cx="260.36" cy="505.17" r="4.97" fill="#3f3d56" />
    <circle cx="260.36" cy="498.96" r="4.97" fill="#3f3d56" />
    <circle cx="260.36" cy="492.74" r="4.97" fill="#3f3d56" />
    <circle cx="267.82" cy="495.23" r="4.97" fill="#3f3d56" />
    <circle cx="272.79" cy="492.74" r="4.97" fill="#3f3d56" />
    <circle cx="277.76" cy="490.26" r="4.97" fill="#3f3d56" />
    <circle cx="260.36" cy="486.53" r="4.97" fill="#3f3d56" />
    <circle cx="260.36" cy="480.32" r="4.97" fill="#3f3d56" />
    <circle cx="260.36" cy="474.11" r="4.97" fill="#3f3d56" />
    <circle cx="260.36" cy="467.9" r="4.97" fill="#3f3d56" />
    <path
      d="M306,588.13q-1.86.27-3.72.39a28.56,28.56,0,0,0,3.2-3.28,21,21,0,0,0-3-5.83,10.22,10.22,0,0,1-4,1.26c-1.23.18-2.47.31-3.71.4,1.63-1.33,3.15-3.33,4.75-5a21.1,21.1,0,1,0,6.93,15.61,20.68,20.68,0,0,0-.32-3.63Z"
      transform="translate(-25 -136.26)"
      fill="#4199e1"
    />
    <path
      d="M322.57,612.82a9.64,9.64,0,0,1-4.18,1.39c-1.23.18-2.47.32-3.71.4a36.4,36.4,0,0,0,3.68-3.82,12.44,12.44,0,1,0,4.21,2Z"
      transform="translate(-25 -136.26)"
      fill="#4199e1"
    />
    <circle cx="252.91" cy="495.23" r="4.97" fill="#3f3d56" />
    <circle cx="247.94" cy="492.74" r="4.97" fill="#3f3d56" />
    <circle cx="242.97" cy="490.26" r="4.97" fill="#3f3d56" />
    <path
      d="M248.16,612.82a9.64,9.64,0,0,0,4.18,1.39c1.23.18,2.47.32,3.71.4a35.42,35.42,0,0,1-3.68-3.82,12.44,12.44,0,1,1-4.21,2Z"
      transform="translate(-25 -136.26)"
      fill="#4199e1"
    />
    <path
      d="M256.79,525h12.11a25,25,0,0,1,25,25v32.87a4.24,4.24,0,0,1-4.24,4.24H236a4.24,4.24,0,0,1-4.24-4.24V550A25,25,0,0,1,256.79,525Z"
      fill="#3f3d56"
    />
    <g opacity="0.1">
      <path
        d="M306.34,589.49a18.83,18.83,0,0,1-1.6,1.52c.57,0,1.13-.09,1.7-.15C306.42,590.4,306.39,589.94,306.34,589.49Z"
        transform="translate(-25 -136.26)"
      />
      <path
        d="M297.28,583.55c1.25-.08,2.48-.21,3.72-.39a12,12,0,0,0,3.23-.88,21.92,21.92,0,0,0-1.74-2.87,7.52,7.52,0,0,1-2,.85A28.79,28.79,0,0,1,297.28,583.55Z"
        transform="translate(-25 -136.26)"
      />
      <path
        d="M266.73,594.22a21.08,21.08,0,0,1,32.5-17.76l.32-.34a21.1,21.1,0,1,0-27.82,31.73A21,21,0,0,1,266.73,594.22Z"
        transform="translate(-25 -136.26)"
      />
    </g>
    <g opacity="0.1">
      <path
        d="M314.68,617.09c1.24-.08,2.48-.21,3.71-.39a9.65,9.65,0,0,0,4.18-1.4,12.43,12.43,0,0,1,5,8.8,11.38,11.38,0,0,0,.07-1.31,12.38,12.38,0,0,0-5-10,9.64,9.64,0,0,1-4.18,1.39l-1,.13A25.37,25.37,0,0,1,314.68,617.09Z"
        transform="translate(-25 -136.26)"
      />
      <path
        d="M315.18,612.85a10.19,10.19,0,0,1,1.24.09c.65-.69,1.29-1.43,1.94-2.15a12.4,12.4,0,0,0-15.6,12c0,.42,0,.83.06,1.24A12.42,12.42,0,0,1,315.18,612.85Z"
        transform="translate(-25 -136.26)"
      />
    </g>
    <g opacity="0.1">
      <path
        d="M256.05,617.09c-1.24-.08-2.48-.21-3.71-.39a9.65,9.65,0,0,1-4.18-1.4,12.43,12.43,0,0,0-5,8.8,11.38,11.38,0,0,1-.07-1.31,12.38,12.38,0,0,1,5-10,9.64,9.64,0,0,0,4.18,1.39l1,.13A23.67,23.67,0,0,0,256.05,617.09Z"
        transform="translate(-25 -136.26)"
      />
      <path
        d="M255.55,612.85a10.51,10.51,0,0,0-1.25.09c-.64-.69-1.28-1.43-1.93-2.15a12.4,12.4,0,0,1,15.6,12c0,.42,0,.83-.06,1.24A12.42,12.42,0,0,0,255.55,612.85Z"
        transform="translate(-25 -136.26)"
      />
    </g>
    <path
      d="M221.85,735.39h-97.7a4.15,4.15,0,0,1-4.15-4.15V685a4.15,4.15,0,0,1,5.93-3.75c28.07,13.32,58.31,17.89,94,.21a4.16,4.16,0,0,1,6,3.71v46A4.15,4.15,0,0,1,221.85,735.39Z"
      transform="translate(-25 -136.26)"
      fill="#4199e1"
    />
    <path
      d="M221.85,735.39h-97.7a4.15,4.15,0,0,1-4.15-4.15V685a4.15,4.15,0,0,1,5.93-3.75c28.07,13.32,58.31,17.89,94,.21a4.16,4.16,0,0,1,6,3.71v46A4.15,4.15,0,0,1,221.85,735.39Z"
      transform="translate(-25 -136.26)"
      opacity="0.1"
    />
    <ellipse cx="147.32" cy="556.41" rx="5.78" ry="7.57" fill="#3f3d56" />
    <ellipse cx="147.32" cy="547.16" rx="5.78" ry="7.57" fill="#3f3d56" />
    <ellipse cx="147.32" cy="537.9" rx="5.78" ry="7.57" fill="#3f3d56" />
    <ellipse cx="147.32" cy="528.65" rx="5.78" ry="7.57" fill="#3f3d56" />
    <ellipse cx="147.32" cy="519.39" rx="5.78" ry="7.57" fill="#3f3d56" />
    <ellipse cx="147.32" cy="510.13" rx="5.78" ry="7.57" fill="#3f3d56" />
    <ellipse cx="147.32" cy="500.88" rx="5.78" ry="7.57" fill="#3f3d56" />
    <path
      d="M194,573.8a29.42,29.42,0,0,0,2.15-3.17l-15.2-2.5,16.44.12a27.71,27.71,0,0,0,.53-22l-22.05,11.44,20.34-15a27.71,27.71,0,1,0-45.77,31,27.1,27.1,0,0,0-3.16,5.05L167,589.1,146,582a27.74,27.74,0,0,0,4.47,26,27.71,27.71,0,1,0,43.56,0,27.71,27.71,0,0,0,0-34.26Z"
      transform="translate(-25 -136.26)"
      fill="#4199e1"
    />
    <path
      d="M144.49,590.93a27.59,27.59,0,0,0,5.93,17.13,27.71,27.71,0,1,0,43.56,0C197.69,603.35,144.49,587.82,144.49,590.93Z"
      transform="translate(-25 -136.26)"
      opacity="0.1"
    />
    <path
      d="M221.85,737.84h-97.7a4.15,4.15,0,0,1-4.15-4.15V685a4.15,4.15,0,0,1,5.93-3.75c28.07,13.32,50.33,28.85,94,.21,2.59-1.7,6,.62,6,3.71v48.5A4.15,4.15,0,0,1,221.85,737.84Z"
      transform="translate(-25 -136.26)"
      fill="#4199e1"
    />
    <polygon
      points="921.83 587.81 777.69 606.88 770.48 607.83 700.01 570.19 700.01 561.38 920.23 537.36 921.83 587.81"
      fill="#9c9ca1"
    />
    <polygon
      points="740.85 574.19 897 555.78 895.4 584.61 773.77 593.53 740.85 574.19"
      fill="#939498"
    />
    <path
      d="M802.69,733.67v9.46l-7.21,1L725,706.45v-8.81l67.27,36S801.09,734.47,802.69,733.67Z"
      transform="translate(-25 -136.26)"
      opacity="0.4"
    />
    <path
      d="M830.36,583.52,798,733.57a9.58,9.58,0,0,0,10.62,11.53L1013.35,718a9.58,9.58,0,0,0,7.84-6.48l44.17-133.2a9.6,9.6,0,0,0-9.56-12.6L839.28,576A9.6,9.6,0,0,0,830.36,583.52Z"
      transform="translate(-25 -136.26)"
      fill="#3f3d56"
    />
    <ellipse
      cx="937.28"
      cy="638.27"
      rx="8.7"
      ry="5.59"
      transform="translate(179.55 1367.46) rotate(-84)"
      fill="#fff"
    />
  </svg>
);

export default HeroImage;
