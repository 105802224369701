import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-36 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <p className="mt-5">
          Flowing Mind provides dylexia and SEN assessment/intervention services.
        </p>
        <p className="text-left text-gray-500 text-xs mt-4">
          &copy;2020 Flowing Mind Ltd. All rights reserved.
        </p>
      </div>

      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="#">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="#">Privacy Policy</a>
          </li>
        </ul>
      </div>
      {/* <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="#">
              <FontAwesomeIcon icon={faFacebookF} />
              Facebook
            </a>
          </li>
          <li>
            <a href="#">
              <FontAwesomeIcon icon={faLinkedinIn} />
              LinkedIn
            </a>
          </li>
          <li>
            <a href="#">
              <FontAwesomeIcon icon={faTwitter} />
              Twitter
            </a>
          </li>
        </ul>
      </div> */}
    </div>
  </footer>
);

export default Footer;
