import React from 'react';

const OnlineTutor = () => (
  <svg
    id="b292e128-983c-4a50-89eb-5eccde426ab3"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="300"
    height="300"
    viewBox="0 0 885.6192 579.76496"
  >
    <rect x="0.26069" y="0.34611" width="610.53198" height="391.01184" fill="#e6e6e6" />
    <rect x="17.71871" y="49.38875" width="575.61593" height="318.83691" fill="#fff" />
    <rect width="610.53198" height="25.93763" fill="#6c63ff" />
    <circle cx="19.27476" cy="13.26069" r="4.80734" fill="#fff" />
    <circle cx="37.52223" cy="13.26069" r="4.80734" fill="#fff" />
    <circle cx="55.76969" cy="13.26069" r="4.80734" fill="#fff" />
    <rect x="66.69233" y="98.33976" width="325.75609" height="183.2378" fill="#6c63ff" />
    <rect x="437.90276" y="98.33976" width="124.05246" height="69.77951" fill="#e6e6e6" />
    <rect x="437.90276" y="190.1954" width="124.05246" height="69.77951" fill="#e6e6e6" />
    <rect x="437.90276" y="282.05104" width="124.05246" height="69.77951" fill="#e6e6e6" />
    <rect x="66.69233" y="312.35393" width="224.43079" height="15.15145" fill="#e6e6e6" />
    <polygon
      points="272.184 189.959 201.161 148.954 201.161 230.963 272.184 189.959 201.161 148.954 201.161 230.963 272.184 189.959"
      fill="#fff"
    />
    <polygon
      points="729.361 539.637 732.279 554.225 717.691 557.143 719.879 538.907 729.361 539.637"
      fill="#ffb8b8"
    />
    <polygon
      points="789.076 536.983 785.772 553.502 772.182 553.83 776.521 533.679 789.076 536.983"
      fill="#ffb8b8"
    />
    <path
      d="M967.92909,527.39544l3.78786,25.56807s-3.78786,56.81792-4.73483,59.65882-3.78786,17.04537-3.78786,17.04537l-16.09841,68.18151s-13.93605,1.89393-16.777-5.68179l.67854-40.71951-.947-46.40131-11.36358-53.03006-19.88628,71.96937s-.947,23.67414-3.78786,32.19682-6.62876,45.45434-6.62876,45.45434-12.04211,2.8409-12.98908,0-5.95022-70.07543-5.95022-80.492-5.56683-110.29488-5.56683-110.29488Z"
      transform="translate(-157.1904 -160.11752)"
      fill="#2f2e41"
    />
    <path
      d="M933.73638,712.67729a11.74878,11.74878,0,0,0,4.73134,0c1.77425-.59142,0-4.13993,0-4.13993h6.50559s7.097,15.96827,10.05409,17.15111,12.41977,14.194,1.18284,14.194a36.71857,36.71857,0,0,1-19.51677-5.91417s-1.18284-5.32276-6.50559-5.91418-8.87126-7.097-8.87126-7.097l5.77716-10.66554a1.91327,1.91327,0,0,1,3.3547-.00669C931.25178,711.70366,932.36731,712.9511,933.73638,712.67729Z"
      transform="translate(-157.1904 -160.11752)"
      fill="#2f2e41"
    />
    <path
      d="M884.23812,710.8453s-1.31913,2.63826-2.63826,1.31913a18.06967,18.06967,0,0,1-2.63826-4.617s-3.95738-1.97869-4.61695,0-3.29782,12.53172-4.61695,13.85085-15.17,15.17-1.31912,17.80823,16.4891-3.29782,16.4891-3.29782-1.97869-3.29782,1.97869-4.61695,4.617-5.93608,4.617-5.93608l-1.98763-14.90725a1.6153,1.6153,0,0,0-2.89516-.74735C885.846,710.6982,884.994,711.34919,884.23812,710.8453Z"
      transform="translate(-157.1904 -160.11752)"
      fill="#2f2e41"
    />
    <path
      d="M989.70929,461.10787l2.8409,49.2422s13.25751,31.24985-4.73483,30.30289-6.62876-31.24986-6.62876-31.24986L963.57081,465.455Z"
      transform="translate(-157.1904 -160.11752)"
      fill="#ffb8b8"
    />
    <path
      d="M843.87662,446.90339l-2.84089,49.2422s-13.25752,31.24985,4.73482,30.30289,6.62876-31.24986,6.62876-31.24986L870.0151,451.2505Z"
      transform="translate(-157.1904 -160.11752)"
      fill="#ffb8b8"
    />
    <circle cx="762.44343" cy="155.15768" r="24.6211" fill="#ffb8b8" />
    <path
      d="M939.04664,325.21834s-6.62876,26.515,6.62876,33.14378-41.66648,16.09842-45.45434,0l1.89393-7.57572s7.57573-9.46965,5.68179-17.99234Z"
      transform="translate(-157.1904 -160.11752)"
      fill="#ffb8b8"
    />
    <path
      d="M924.36868,358.83561s-16.34007,1.526-21.42752-9.18015c0,0-38.23128,2.55139-46.754,37.58911,9.89088.283,22.16074,112.83482,5.95021,124.05246-13.15874,9.10582,22.70631,23.13129,72.6479,23.67414l37.87863-1.89393c-7.06282-6.08448-23.96336-85.27142-13.25752-84.27992,0,0,28.409-44.50737,25.568-51.13613s-7.39048-32.76573-43.94129-43.37138C941.03317,354.28981,940.46709,359.78257,924.36868,358.83561Z"
      transform="translate(-157.1904 -160.11752)"
      fill="#e6e6e6"
    />
    <path
      d="M972.66391,381.56278l12.31055,16.09841s12.31055,67.23454,8.52269,68.1815-26.515,8.52269-30.30289,5.6818S942.361,412.81263,942.361,412.81263Z"
      transform="translate(-157.1904 -160.11752)"
      fill="#e6e6e6"
    />
    <path
      d="M872.28558,376.828l-16.09841,10.41662S832.513,453.53214,839.14179,456.373s43.56041.947,43.56041.947Z"
      transform="translate(-157.1904 -160.11752)"
      fill="#e6e6e6"
    />
    <path
      d="M946.54636,293.24121s-8.52806-18.47745-24.8735-14.21342-25.58419,10.66007-26.29482,17.0561.35534,15.9901.35534,15.9901,1.77666-13.14741,13.14741-10.30472,29.13751.71066,29.13751.71066l2.84268,25.58416s3.198-4.61938,6.75141-1.77669S957.91711,298.92655,946.54636,293.24121Z"
      transform="translate(-157.1904 -160.11752)"
      fill="#2f2e41"
    />
    <polygon
      points="885.258 579.242 641.189 579.242 641.189 577.247 885.619 577.247 885.258 579.242"
      fill="#3f3d56"
    />
  </svg>
);

export default OnlineTutor;
