import React from 'react';

const Form = () => (
  <div class="w-full max-w-xl">
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
    >
      <p>
        <label class="block text-gray-700 text-sm font-bold mb-8">
          Name:{' '}
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="name"
            name="name"
          />
        </label>
      </p>
      <p>
        <label class="block text-gray-700 text-sm font-bold mb-8">
          Email:{' '}
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            name="email"
            placeholder="email"
          />
        </label>
      </p>
      <p>
        <label class="block m-auto text-gray-700 text-sm font-bold mb-8">
          Message:{' '}
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="message"
            placeholder="message"
          ></textarea>
        </label>
      </p>
      <p>
        <button
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          Send
        </button>
      </p>
    </form>
  </div>
);

export default Form;
